import { useEffect } from 'react';
import { navigate } from 'gatsby';

/**
 * Redirect to homapage if `condition` full fill
 * @param {boolean} condition
 */
function useRedirectToHomepage(condition) {
  useEffect(() => {
    if (condition) {
      navigate('/');
    }
  }, []);
}

export default useRedirectToHomepage;
